import { render, staticRenderFns } from "./EmailEditor.vue?vue&type=template&id=195963a4&scoped=true"
import script from "./EmailEditor.vue?vue&type=script&lang=js"
export * from "./EmailEditor.vue?vue&type=script&lang=js"
import style0 from "./EmailEditor.vue?vue&type=style&index=0&id=195963a4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195963a4",
  null
  
)

export default component.exports